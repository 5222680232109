import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./styles";
import { RoundTableHead } from "../TableHead/RoundTableHead";
import clsx from "clsx";
import { DOBField } from "./DOBField";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import addedPatient from "../../iamges/write.png";
import mergedPatient from "../../iamges/mergedPatient.png";
import Tooltip from "@material-ui/core/Tooltip";
import { triageType, itemPerPage } from "../../constants/constants";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {
    [key in Key]:
      | number
      | string
      | boolean
      | ILastRoundNotes[]
      | string[]
      | null;
  },
  b: {
    [key in Key]:
      | number
      | string
      | boolean
      | ILastRoundNotes[]
      | string[]
      | null;
  }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: IData[],
  comparator: (a: IData, b: IData) => number
) {
  const manuallyArr = array.filter((patient) => patient.manuallyAdd === "Y");
  const sortableArr = array.filter((patient) => patient.manuallyAdd !== "Y");
  const stabilizedThis = sortableArr.map(
    (el, index) => [el, index] as [IData, number]
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const arr = [...manuallyArr, ...stabilizedThis.map((el) => el[0])];
  return arr;
}

interface IRoundTableProps {
  isFormOpen: boolean;
  showPopup: (type: string, message: string, rn: number) => void;
}

export default function RoundTable({
  isFormOpen,
  showPopup,
}: IRoundTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IData>("edlos");
  const [selected, setSelected] = useState<number>();

  const { filteredPatients, currentPage } = useSelector(
    (state: RootState) => state.patient
  );
  const startIndex = (currentPage - 1) * itemPerPage;
  const endIndex = startIndex + itemPerPage;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getEdlosFormat = (edlos: string) => {
    const hr = edlos.split(":")[0];
    const min = edlos.split(":")[1];
    if (Number(hr) === 0) {
      let res = "";
      Number(min) === 0 ? (res = "0min") : (res = `${min}mins`);
      return res;
    } else {
      return `${Number(hr)}hr ${min}mins`;
    }
  };
  const handleClick = (rn: number) => {
    setSelected(rn);
  };

  const bgColorsMap = {
    [triageType.CAT1]: "red",
    [triageType.CAT2]: "orange",
    [triageType.CAT3]: "#4CBB17",
    [triageType.CAT4]: "#6689D6",
    [triageType.CAT5]: "#fff",
  };

  const getTriageColor = (triageCat: string): string => {
    return bgColorsMap[triageCat] ?? "#fff";
  };

  return (
    <div className={clsx(classes.root, { [classes.backdrop]: isFormOpen })}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <RoundTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredPatients &&
                stableSort(filteredPatients, getComparator(order, orderBy))
                  .slice(startIndex, endIndex)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        className={
                          row?.currentMedDS
                            ? classes.rowCurrentDiscussed
                            : row?.lastRoundNotes &&
                              row?.lastRoundNotes?.length &&
                              row?.lastRoundNotes?.some(
                                (note) =>
                                  !!note.previousMedDs ||
                                  !!note.previousRoundNotes
                              )
                            ? classes.rowPreviousDiscussed
                            : row.allocateDoctor === "Y" ||
                              row?.lastRoundNotes?.some(
                                (note) => note.prevAllocateDoctor === "Y"
                              )
                            ? classes.rowAllocatedDoctor
                            : classes.row
                        }
                        // hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${index}-${row.rn}`}
                        onClick={() => handleClick(row.rn)}
                        selected={selected === row.rn ? true : false}
                      >
                        <TableCell
                          width="4%"
                          align="center"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.Location.split("(")[0]}
                        </TableCell>
                        <TableCell
                          width="4%"
                          align="center"
                          padding="none"
                          style={{
                            backgroundColor: getTriageColor(row.triage),
                          }}
                        >
                          {row.triage}
                        </TableCell>
                        <TableCell width="8%" align="center" padding="none">
                          {row.edlos
                            ? getEdlosFormat(row.edlos)
                            : row.manuallyAdd === "Y"
                            ? ""
                            : ">24hrs"}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.URNO ?? ""}
                        </TableCell>
                        <TableCell width="10%" align="center" padding="none">
                          <button
                            onClick={() => showPopup("docPlan", "", row.rn)}
                          >{`${row.firstname} ${row.lastname}`}</button>
                          {row.manuallyAdd === "Y" && (
                            <img
                              alt="manually add"
                              width="20"
                              height="20"
                              src={addedPatient}
                            />
                          )}
                          {row.manuallyAdd === "M" && (
                            <img
                              alt="merged"
                              width="20"
                              height="20"
                              src={mergedPatient}
                            />
                          )}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          <div className={classes.grayText}>
                            {`${
                              row.gender === "F" ||
                              row.gender?.toUpperCase() === "FEMALE"
                                ? "Female"
                                : row.gender === "M" ||
                                  row.gender?.toUpperCase() === "MALE"
                                ? "Male"
                                : "Unknown"
                            }`}
                          </div>
                        </TableCell>
                        <DOBField dob={row.dob} />
                        <TableCell width="4%" align="center" padding="none">
                          {row.age}
                        </TableCell>
                        <TableCell width="6%" align="center" padding="none">
                          <Tooltip
                            classes={{ tooltip: classes.customTooltip }}
                            title={row["Presentation Comment"]}
                          >
                            <div className={classes.longTextContent}>
                              {row["Presentation Comment"]}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          width="4%"
                          align="center"
                          padding="none"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {row.Doctor?.join(", ") ?? ""}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          <Tooltip
                            classes={{ tooltip: classes.customTooltip }}
                            title={row.triageInformation}
                          >
                            <div className={classes.longTextContent}>
                              {row.triageInformation}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.temp}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.hr}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.bp}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.rr}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.sats}
                        </TableCell>
                        <TableCell width="4%" align="center" padding="none">
                          {row.bgl}
                        </TableCell>
                        {/* <TableCell width="4%" align="center" padding="none">
                          <Tooltip
                            classes={{ tooltip: classes.customTooltip }}
                            title={row.diagnosis}
                          >
                            <div className={classes.longTextContent}>
                              {row.diagnosis}
                            </div>
                          </Tooltip>
                        </TableCell> */}
                        <TableCell width="4%" align="center" padding="none">
                          {row.currentMedDS}
                        </TableCell>
                        {/* <TableCell width="4%" align="center" padding="none">
                          {row.siteDS}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
