import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      "& .MuiTableCell-root": {
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
      },
      maxHeight: "90vh",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    row: {
      height: "35px",
    },
    rowPreviousDiscussed: {
      height: "35px",
      backgroundColor: "#FFFDE8",
    },
    rowCurrentDiscussed: {
      height: "35px",
      backgroundColor: "#C8E6C9",
    },
    rowAllocatedDoctor: {
      height: "35px",
      backgroundColor: "#c8d5e6",
    },
    tableCell: {
      backgroundColor: "pink",
    },
    actionRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "0.2em",
    },
    textField: {
      width: "100%",
      height: "138px",
    },
    errTextField: {
      width: "100%",
      height: "138px",
      border: "1px solid red",
    },
    backdrop: {
      opacity: 0.3,
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
    highlightBorder: {
      border: "1px solid red",
      width: "100%",
      height: "100%",
      minheight: "180px",
      minWidth: "300px",
    },
    discussGroup: {
      border: "1px solid blue",
      borderRadius: "10px",
    },
    errGroup: {
      border: "1px solid red",
      borderRadius: "10px",
    },
    grayText: {
      color: "gray",
    },
    errorBorder: {
      borderWidth: 1,
      borderColor: "red",
      borderStyle: "solid",
    },
    highlight: {
      backgroundColor: "orange",
    },
    text: {
      width: "100%",
      textAlign: "left",
      paddingLeft: "0.2em",
    },
    lastRoundNotes: {
      width: "100%",
    },
    longTextContent: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100px",
    },
    noPaddingCell: {
      padding: "0", // Set padding to zero
    },
    customTooltip: {
      fontSize: "16px",
    },
  })
);
