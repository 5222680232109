import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // width: "500px",
    // height: "460px",
    border: "5px solid white",
    paddingTop: "20px",
    // backgroundColor: "#fff",
    backgroundColor: "lightgray",

    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
  },
  size: {
    width: "860px",
  },
  loadingIcon: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20%",
    fontSize: "20px",
  },
  // btn: {
  //   width: "140px",
  // },
  // cancelBtn: {
  //   backgroundColor: "gray",
  // },
  optionText: {
    color: "#000",
    marginTop: "2rem",
  },
  errText: {
    color: "red",
    marginTop: "2rem",
  },
  // btnGroup: {
  //   width: "100%",
  //   display: "flex",
  //   justifyContent: "space-around",
  // },
  topContainer: {
    flex: 1,
  },
  box: {
    width: "100%",
    height: "100%",
    color: "#000",
    fontSize: "14px",
    fontWeight: "bold",
  },
  row: {
    width: "100%",
    // display: "flex",
    marginTop: "0.5rem",
  },
  section: {
    width: "410px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    position: "relative",
    zIndex: 1,
  },
  btnGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  btn: {
    width: "100px",
    height: "50px",
    marginTop: "0.5rem",
    backgroundColor: "#005992",
  },
  cancelBtn: {
    backgroundColor: "gray",
  },
  textField: {
    width: "410px",
    marginTop: "0.2rem",
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  iconContianer: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    zIndex: 999,
    marginTop: "-1rem",
    marginLeft: "1rem",
  },
  icon: {
    transform: "scale(1.2)",
    color: "#0ab8f2",
  },
  dispositionSection: {
    width: "100%",
    marginTop: "0.5rem",
  },
  dispositionOptions: {
    display: "flex",
    marginLeft: "0.5rem",
  },
  subSection: {
    lineHeight: "43px",
    textAlign: "center",
  },
  text: {
    marginLeft: "0.5rem",
  },
  input: {
    height: "10px",
    background: "white",
    border: "none",
  },
  disabledInput: {
    height: "10px",
    background: "#bdbcb9",
    border: "none",
  },
  commentField: {
    width: "100%",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  summary: {
    wdith: "100%",
    height: "60px",
    backgroundColor: "#005992",
    borderRadius: "16px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
  },
  errMessage: {
    color: "red",
  },
  hide: {
    display: "none",
  },
  margin: {
    marginBottom: "0.5rem",
  },
  reduceTimeSection: {
    width: "100%",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    position: "relative",
    zIndex: 1,
  },
  smallRadioButton: {
    marginRight: "2rem",
    "& svg": {
      width: "0.6em",
      height: "0.6em",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  radioLabel: {
    fontSize: "0.9rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  signs: {
    width: "70%",
    display: "flex",
    justifyContent: "space-around",
  },
  label: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "18px",
  },
  multiInputRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
  },
  dateTime: {
    fontWeight: "normal",
  },
}));
